import http from '@/utils/request'
import Qs from 'qs'
/**
 * 登录注册
 */
export default {
    login: (data) => http.post('/wx/user/login', Qs.stringify(data)), // 登录
    loginSms: (data) => http.post('/wx/user/loginSms', Qs.stringify(data)), // 登录时时获取验证码
    accountLogin: (data) => http.post('/wx/user/accountLogin', Qs.stringify(data)), // 会员_账号登录
    register: (data) => http.post('/wx/user/register', Qs.stringify(data)), // 注册
    registerSms: (data) => http.post('/wx/user/registerSms', Qs.stringify(data)), // 注册时获取验证码
    getAccessToken: (data) => http.get('/wx/user/auth/index?returnUrl', { params: data }), // 用户授权获取--accessToken
	userautoRegister: (data) => http.post('/wx/user/autoRegister', Qs.stringify(data)), //维新过来的用户自动注册
}
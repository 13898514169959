<template>
    <div class="register">
        <!-- logo -->
        <div class="logo"></div>
        <!-- 注册信息 -->
        <div class="register-info">
            <van-field class="van-input" v-model="parmes.mobile" placeholder="请输入手机号码" />
            <van-field class="van-input" type="password" v-model="parmes.password" placeholder="请输入密码" />
            <van-field
                class="van-input" 
                v-model="parmes.verifyCode"
                center
                clearable
                placeholder="请输入短信验证码"
            >
                 <div ref="code" :class="{'get-code':true, 'code-btn-disabled':isSend}" slot="button" @click="getVerifyCode">获取验证码</div>
            </van-field>
            <van-field class="van-input"  v-model="parmes.inviteCode" placeholder="请输入邀请码（选填）" />

        </div>
        <!-- 用户服务协议 -->
        <div class="service-contract">
            <van-icon color="#4DC17E" name="checked" size="15" />
            我已阅读并同意
            <a :href="`${baseUrl}/upload/protocol.htm`">《用户服务协议》</a>
        </div>
        <!-- 按钮 -->
        <div class="btn">
            <button class="button—color" @click="register">注册</button>
        </div>
    </div>
</template>
<script>
import { Field , Icon, Toast } from 'vant';
import { mapGetters } from 'vuex';
import LoginApi from '@/api/login/loginApi';
export default {
    components: {
        [Field.name]: Field,
        [Icon.name]: Icon 
    },
    data() {
        return {
            parmes: {
                projectId: '',           //项目id
                ddMemberId: '',          //丁丁医生会员的id
                mobile: '',             // 手机号
                password: '',           // 密码
                inviteCode: '',         // 邀请码
                verifyCode: '',         // 验证码

            },
            codeTimer: null,
            formInfo: {
               mobile: '请输入手机号' ,
               password: '请输入8-16位数字和字母组合新密码',
               verifyCode: '请输入短信验证码'
            },
            isSend: false,               // 是否发送验证码
            isRegister: false           // 是否提交注册
            }
    },
    computed:{
        ...mapGetters({ // 获取用户信息
            baseUrl: 'baseUrl',
            targetPath: 'targetPath'
      })
    },
    created(){
        const {projectId,ddMemberId} = this.$route.query;
        console.log("ddMemberId:--"+ddMemberId);
        this.parmes.projectId = projectId || '';
        this.parmes.ddMemberId = ddMemberId || '';
    },
    methods: {
        // 注册
        register() { 
            const { path} = this.$route.query
            const {parmes, formInfo } = this;
            if(this.isRegister) return false;

            for(const i in formInfo) {

                if(!parmes[i]) {
                    Toast(`请填写${formInfo[i]}`)
                    return
                }
                if (i === 'mobile') {
                    if (!/^1[0-9]{10}$/.test(parmes[i])) {
                        Toast('请填写正确手机号码')
                        return
                    }
                }
            }
            this.isRegister = true;
            LoginApi.register({ ...this.parmes }).then(res => {
                // this.$createToast({ txt: res.msg, type: 'correct' }).show()
                clearInterval(this.codeTimer)
                localStorage.setItem('healthme_token', res.data.token)
                this.$store.commit('SET_TOKEN', res.data.token)
                    // window.location.href = this.targetPath.path
                    this.$router.replace({
                    path: path || '/',
                    query: {id: this.parmes.projectId || '', ddMemberId: this.parmes.ddMemberId || '' }
                })
            }).catch(err => {
                this.isRegister = false;
                Toast(err)
            })
        },
        // 获取验证码
        getVerifyCode() {
            const { mobile } = this.parmes
            if (this.isSend) return false
            if (!mobile) {
                Toast(`请填写手机号`)
                return;
            }else if(!/^1[0-9]{10}$/.test(mobile)) {
                Toast('请填写正确手机号码')
                return;
            }
            this.isSend = true;
            LoginApi.registerSms({ mobile }).then(res => {
                Toast('获取验证码成功')
                setTimeout(() => {
                    this.isSend = true
                    let ss = 60
                    this.codeTimer = setInterval(() => { // 倒计时
                        if (ss <= 1) {
                            this.$refs.code.innerHTML = '重新获取'
                            this.isSend = false
                            clearInterval(this.codeTimer)
                            return false
                        } else {
                            ss--
                            this.$refs.code.innerHTML = ss + 's 后重新获取'
                        }
                    }, 1000)
                }, 1000)
            }).catch(err => {
                console.log(err)
                this.isSend = false;
                Toast(err)
            })
        },
    },
    destroyed() {
        clearInterval(this.codeTimer)
    }
}
</script>
<style lang="less" scoped>
.register {
    padding: 1.733333rem .4rem 0 .4rem;
    .logo {
       width: 2rem;
       height: 2rem; 
       margin: 0 auto;
       background: url('../../assets/images/logo.png') no-repeat;
       background-size: 100% 100%;
    }
    .register-info {
        margin-top: 1.266667rem;
        .van-input {
            width: 100%;
            height: 1.2rem;
            font-size: 15px;
            color: #272727;
            padding: 0 0 0 .266667rem;
            margin-bottom: 0.4rem;
            background: #F7F7F7;
            line-height: 1.2rem;
            &::after {
                border: none;
            }
        }
        .label-text {
            img {
                font-size: 0;
                vertical-align: middle;
            }
        }
        .get-code {
            width: 3.253333rem;
            height: 1.2rem;
            font-size: 15px;
            color: #fff;
            line-height: 1.2rem;
            text-align: center;
            background: #009DF3;
        }
        .code-btn-disabled {
            font-size: .32rem;
            color: #bbb;
            background: none;
            border: 1px solid #DEDEDE;
        }
    }
    .service-contract {
        margin-top: .266667rem;
        font-size: .373333rem;
        a {
            color: #009DF3;
        }
    }
    .btn {
        margin-top: 1.066667rem;
        button {
            width: 100%;
            height: 1.2rem;
            font-size: 18px;
            color: #fff;
            background: #009DF3;
            border-radius: .133333rem;
            margin-bottom: .4rem;
            border: none;
        }
    }
}
</style>

